import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

// Hooks & Querys
import { QueryFragments } from "../Graphql/queryFragments" // eslint-disable-line
import { useMediaQuery } from "@react-hook/media-query"
import { useAppContext } from "../components/layout"

import { PageMeta } from "../components/pageMeta"

// Animation Controls
import { headingVariants, imageVariants } from "../config/animation"

const IndexPage = ({ data: { page } }) => {
  const { meta, pageImage, headline } = page || {}
  const { setReverseHeader } = useAppContext()

  const matches = useMediaQuery("only screen and (min-width: 768px)")

  useEffect(() => {
    setReverseHeader(true)
    return () => {
      setReverseHeader(false)
    }
  }, [])

  return (
    <>
      <PageMeta {...meta} />
      <div className="min-h-screen flex items-center justify-center px-6">
        <motion.h1
          className="leading-relaxed text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-display text-center text-white relative max-w-5xl 2xl:max-w-7xl lg:leading-relaxed 2xl:leading-relaxed z-10"
          variants={headingVariants}
        >
          {headline}
        </motion.h1>
        <motion.div
          className="h-full w-full absolute top-0 left-0 bg-black z-0"
          variants={imageVariants}
          custom={matches}
        >
          {pageImage && pageImage.asset && (
            <Img
              fluid={pageImage.asset.fluid}
              className="h-full w-full top-0 left-0 object-cover z-0"
              style={{ position: "absolute", opacity: "0.5" }}
            />
          )}
        </motion.div>
      </div>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    page: sanityHomePage {
      meta {
        ...MetaCardFields
      }
      headline
      pageImage {
        asset {
          fluid(maxWidth: 1440) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
